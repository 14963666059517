import React, { useState } from 'react';
import More from 'resources/svg/more.svg';
import { Player } from 'components/Player';
import { GatsbyImage } from 'gatsby-plugin-image';

interface Props {
  className?: string;
  item: GatsbyTypes.RssEpisode;
}

export const Episode: React.FC<Props> = (props) => {
  const { className = 'flex', item } = props;
  const [displayContent, setDisplayContent] = useState<boolean>(false);

  return (
    <div
      className={`${className} flex-col sm:flex-row bg-dark p-6 rounded-4xl shadow-card mb-8`}
    >
      <div className="flex flex-auto h-auto items-stretch flex-col">
        <h3 className="text-white sm:text-lg md:text-2xl lg:text-3xl font-extrabold">
          {item.title}
        </h3>
        {item.isoDate && (
          <time className="text-white text-opacity-75 text-sm">
            {new Date(item.isoDate).toLocaleDateString('de-DE', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })}
          </time>
        )}
        {item.contentSnippet && (
          <>
            <p
              className={`episode-content ${
                displayContent ? '' : ' line-clamp-2'
              }  mt-2`}
              dangerouslySetInnerHTML={{
                __html: item.contentSnippet,
              }}
            />
            <div
              className="flex my-2 text-yellow hover:text-yellow hover:text-opacity-80 items-center"
              onClick={() => setDisplayContent(!displayContent)}
            >
              <div className="">Details</div>
              <span className="ml-2 leading-normal rotate-90 mb-1">
                <More />
              </span>
            </div>
          </>
        )}
        {item.enclosure?.url && (
          <div className="flex grow">
            <Player
              className="mt-2 self-end"
              type="small"
              audio_file={item.enclosure.url}
            />
          </div>
        )}
      </div>
      {item?.itunes?.cover && (
        <GatsbyImage
          imgClassName="rounded-md"
          className=" rounded-md min-h-[12rem] min-w-[12rem] h-[12rem] w-[12rem] mb-6 sm:mb-0 sm:ml-6 order-first sm:order-last"
          image={item.itunes.cover.childImageSharp?.gatsbyImageData!}
          alt={item.title!}
        />
      )}
    </div>
  );
};
