import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { Meta } from 'components/Meta';
import Layout from 'components/Layout';
import { AudioCards } from 'components/AudioCards';
import { toCleanString, toHtml } from 'helper/markdown';
import { Player } from 'components/Player';
import { ExternalLink } from 'components/ExternalLink';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Title } from 'components/Title';
import ArrowLeft from 'resources/svg/arrow-left.svg';
import Amazon from 'resources/svg/amazon.svg';
import Apple from 'resources/svg/apple.svg';
import Spotify from 'resources/svg/spotify.svg';
import Url from 'resources/svg/url.svg';
import { Episodes } from 'components/Episodes';

type Props = {
  pageContext: {
    item: GatsbyTypes.MarkdownRemark;
    underline: GatsbyTypes.File;
    cards: ReadonlyArray<GatsbyTypes.MarkdownRemark>;
  };
};

const Page: React.FC<Props> = (props) => {
  const { cards, underline } = props.pageContext;
  const { frontmatter, episodes } = props.pageContext.item;
  const [randomCards, setRandomCards] = useState(
    Array<GatsbyTypes.MarkdownRemark>(),
  );

  const random = () => {
    return cards[Math.floor(Math.random() * cards.length)];
  };

  useEffect(() => {
    let map = new Map<string, GatsbyTypes.MarkdownRemark>();
    while (map.size < 3) {
      let item = random();
      map.set(item.id, item);
    }
    setRandomCards(Array.from(map.values()));
  }, []);

  return (
    <Layout>
      <main>
        <section className="grid grid-cols-1 gap-8 mt-8 sm:grid-cols-2 lg:grid-cols-3">
          <GatsbyImage
            imgClassName="rounded-4xl"
            className="flex col-span-1 -mx-3 text-white sm:order-last lg:order-first aspect-square grow rounded-4xl shadow-card sm:mx-0"
            image={frontmatter?.image?.childImageSharp?.gatsbyImageData!}
            alt={frontmatter?.title!}
          />
          <div className="flex flex-col self-center col-span-1 lg:col-span-2">
            <div>
              <Link
                className="inline-flex items-center py-1 font-extrabold"
                to="/"
              >
                <div className="mr-1 leading-normal">
                  <ArrowLeft />
                </div>
                <span className="mt-0.5">Zur Startseite</span>
              </Link>
            </div>

            <Title title={frontmatter?.title!} image={underline} />

            {frontmatter?.tags && (
              <ul className="flex">
                {frontmatter.tags.map((tag, index) => (
                  <li key={index} className="flex font-semibold">
                    <small>{tag?.text}</small>
                    <span className="mx-2">
                      {index < frontmatter?.tags?.length! - 1 && '  ·  '}
                    </span>
                  </li>
                ))}
              </ul>
            )}

            <div className="flex flex-col flex-wrap mt-8 space-y-2 lg:space-y-0 lg:space-x-3 lg:flex-row xl:space-x-4">
              <Player
                className="my-4 lg:my-0"
                audio_file={frontmatter?.audio_file?.publicURL!}
                type="dark"
              />
              <ExternalLink url={frontmatter?.spotify_url} title="Spotify">
                <Spotify />
              </ExternalLink>
              <ExternalLink url={frontmatter?.apple_url} title="Apple Podcasts">
                <Apple />
              </ExternalLink>
              <ExternalLink url={frontmatter?.amazon_url} title="Amazon">
                <Amazon />
              </ExternalLink>
              <ExternalLink url={frontmatter?.audiothek_url} title="Audiothek">
                <Url />
              </ExternalLink>
              {frontmatter?.websites &&
                frontmatter?.websites.map((item, index) => (
                  <ExternalLink
                    key={index}
                    url={item?.url}
                    title={item?.label ? item?.label : 'Webseite'}
                  >
                    <Url />
                  </ExternalLink>
                ))}
            </div>
          </div>
        </section>

        <section className="mt-12 md:mt-24">
          <p
            dangerouslySetInnerHTML={{
              __html: toHtml(frontmatter?.description!),
            }}
          />
        </section>

        <Episodes items={episodes} />

        {frontmatter?.infos && (
          <section className="grid gap-8 mt-12 lg:grid-cols-4 md:mt-24">
            <ul className="grid col-span-3 lg:grid-cols-3">
              {frontmatter.infos.map((info, index) => (
                <li key={index} className="flex flex-col mb-6 font-semibold">
                  <label>{info?.label}</label>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: toHtml(info?.text!),
                    }}
                  />
                </li>
              ))}
            </ul>
          </section>
        )}

        <section className="my-12 md:my-24">
          <h2 className="mb-12">Direkt weiterhören.</h2>
          <AudioCards items={randomCards} type="small" />
        </section>
      </main>
    </Layout>
  );
};

export const Head = (props: Props) => {
  const { frontmatter } = props.pageContext.item;
  return (
    <Meta
      title={toCleanString(frontmatter?.title!)}
      publicURL={frontmatter?.image?.publicURL!}
      description={frontmatter?.cover?.subtitle!}
    />
  );
};

export default Page;
