import React, { useEffect, useRef, useState } from 'react';
import { Episode } from 'components/Episode';
import { motion } from 'framer-motion';

interface Props {
  items: GatsbyTypes.Maybe<
    readonly GatsbyTypes.Maybe<GatsbyTypes.RssEpisode>[]
  >;
}

export const Episodes: React.FC<Props> = (props) => {
  const { items } = props;
  const [displayAll, setDisplayAll] = useState<boolean | null>(null);
  const section = useRef<HTMLHeadingElement>(null);

  if (!items || items.length == 0) {
    return <></>;
  }

  useEffect(() => {
    if (displayAll != null && !displayAll && section.current) {
      section.current.scrollIntoView();
    }
  }, [displayAll]);

  return (
    <section
      ref={section}
      className="flex flex-col mt-0 pt-24 xs:pt-32 md:pt-48"
    >
      <h2 className="mb-8">Aktuelle Folge</h2>
      <div className="flex flex-col -mx-3 lg:-mx-4 xl:-mx-6">
        <Episode
          key={items.slice(0, 1)[0]?.id}
          item={items.slice(0, 1)[0]!}
          className="flex"
        />
        <motion.div
          animate={displayAll ? 'open' : 'closed'}
          variants={{
            open: { opacity: 1, y: 0 },
            closed: { opacity: 0, y: '0%' },
          }}
        >
          {items.slice(1, items.length).map((item) => {
            return (
              item && (
                <Episode
                  key={item.id}
                  item={item}
                  className={`${displayAll ? 'flex' : 'hidden'}`}
                />
              )
            );
          })}
        </motion.div>
      </div>

      <button
        className="flex items-center button whitespace-nowrap bg-white dark:bg-dark self-end hover:bg-opacity-100 hover:bg-white"
        onClick={() => setDisplayAll(!displayAll ? true : false)}
      >
        {displayAll ? <span>Weniger Folgen</span> : <span>Alle Folgen</span>}
      </button>
    </section>
  );
};
