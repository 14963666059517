import React, { ReactNode } from 'react';

interface Props {
  url?: string | GatsbyTypes.Maybe<string>;
  children?: ReactNode;
  title?: string;
}

export const ExternalLink: React.FC<Props> = (props) => {
  const { children, title, url } = props;

  return url ? (
    <a
      href={url as any}
      className="flex items-center"
      rel="noopener noreferrer"
      target="_blank"
    >
      <div className="mr-1 leading-none">{children}</div>
      <div>{title}</div>
    </a>
  ) : null;
};
